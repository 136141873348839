import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { requireLogin: Boolean }

  connect() {
    if(this.requireLoginValue) {
      this.setPostLoginState();
    }
    else {
      this.performPostLoginAction();
    }
  }

  setPostLoginState() {
    this.element.addEventListener('click', (e) => {
      const dialog = document.querySelector("dialog[data-name='user-sign-in-modal']");

      if (dialog) {
        e.preventDefault();
        sessionStorage.setItem('postLoginElementId', this.element.id);

        dialog.querySelector('.btn-close').addEventListener('click', () => {
          sessionStorage.removeItem('postLoginElementId');
        });

        dialog.addEventListener('cancel', () => {
          sessionStorage.removeItem('postLoginElementId');
        });

        dialog.removeAttribute('close');
        dialog.showModal();
      }
    });
  }

  performPostLoginAction() {
    if (sessionStorage.hasOwnProperty('postLoginElementId')) {
      const element = document.getElementById(sessionStorage.getItem('postLoginElementId'));

      if (element) {
        setTimeout(() => {
          element.click();
        }, 100);
      }

      sessionStorage.removeItem('postLoginElementId');
    }
  }
}
